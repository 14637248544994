body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", sans-serif;
}

.App {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  margin: 32px;
}
.App-logo {
  margin: 0 10px;
  height: 4vmax;
  pointer-events: none;
}

.App-body {
  flex-grow: 1;
  height: 100%;
  margin: 10px 32px;
}
.note-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.note {
  display: flex;
  align-items: center;
}
.note-content {
  flex-grow: 1;
}
.note-content-done {
  opacity: 0.5;
  text-decoration: line-through;
}
.note-edit {
  width: 100%;
}
.note-buttons {
}
.note-detail {
  margin-top: 32px;
}
.note-filters {
  margin-left: 32px;
  margin-right: 32px;
  display: flex;
  justify-content: space-evenly;
}

.App-footer {
  margin: 32px;
}
.div-input-add {
  display: flex;
  align-items: center;
  width: 100%;
}
.input-add {
  width: 100%;
}
.btn-add {
  margin-left: 10px;
}

.splash-header {
  background-color: #ffd280;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 256px;
}
.splash-content-header {
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  margin: 32px;
}
.splash-content-body {
  text-align: center;
  margin: 16px;
  color: #666666;
}
